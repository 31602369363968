$(() => {
  if (window.location.href.indexOf('board_members') !== -1) {

    $('.next, .previous').on('click tap', (e) => {
      const thisModal = $(e.target).closest('.modal')
      const thisModalIndex = $('.modal').index(thisModal)
      const lastModal = $('.modal').length - 1
      let nextModal = $('.modal')[thisModalIndex + 1]
      let prevModal = $('.modal')[thisModalIndex - 1]

      if (lastModal === thisModalIndex) {
        nextModal = $('.modal')[0]
      } else if (thisModalIndex === 0) {
        prevModal = $('.modal')[lastModal]
      }

      thisModal.modal('hide')

      if ($(e.target).hasClass('next')) {
        $(nextModal).modal('show')
      } else if ($(e.target).hasClass('previous')) {
        $(prevModal).modal('show')
      }
    })

    const updateCounter = () => {
      $('.total').each((i, e) => {
        $(e).html($('.modal').length)
      })

      $('.current').each((i, e) => {
        $(e).html(i + 1)
      })
    }

    updateCounter()
  }
})
