$(() => {
  if ($('.present').index() !== -1) {
    $('#present-tab').on('click', () => {

      $('.present-video source').each(function () {
        const sourceFile = $(this).attr('data-src')
        $(this).attr('src', sourceFile)
        const video = this.parentElement
        video.load()
        video.play()
      })
    })
  }
})
