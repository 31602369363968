const api = require('./custom/map-logic/api.js')
const events = require('./custom/map-logic/events.js')
const ui = require('./custom/map-logic/ui.js')
const hello = require('./custom/js1.js')
const fillBox = require('./custom/fill-box.js')
const showMoreOrLess = require('./custom/show-more.js')
const tableSorting = require('./custom/amenities-nav.js')
const vpDetect = require('./custom/vpDetect.js')
const vacancyCounter = require('./custom/vacancyCounter.js')
const smoothScroll = require('./custom/smoothScroll.js')
const newsPagination = require('./custom/newsPagination.js')
const playVideo = require('./custom/playVideo.js')
const boardModalInteraction = require('./custom/boardModalInteractions.js')
