$(() => {
  const moreOrLess = (event) => {
    if ($(event.target).hasClass('hidden')) {
      $(event.target).removeClass('hidden').html('LESS')
      $('.fa-chevron-down').each(function (i, e) {
        $(e).removeClass('fa-chevron-down').addClass('fa-chevron-up')
      })
    } else {
      $(event.target).addClass('hidden').html('MORE')
      $('.fa-chevron-up').each(function (i, e) {
        $(e).removeClass('fa-chevron-up').addClass('fa-chevron-down')
      })
    }
  }

  $('.view-more').on('click', (event) => {
    moreOrLess(event)
    $('.hide').each((i, e) => {
      $(e).toggleClass('show')
    })
  })

  $('.more-table-rows').on('click', function (event) {
    moreOrLess(event)
    $('.hidden-element').each((i, e) => {
      $(e).toggleClass('show')
    })
  })

  $('.accordion-toggler').on('click tap', (event) => {
    console.log(event)
    if ($(event.currentTarget).find('.fa-lg').hasClass('fa-chevron-up')) {
      $(event.currentTarget).find('.fa-lg').toggleClass('fa-chevron-down fa-chevron-up')
    } else {
      $('.chevron-toggle.fa-chevron-up').toggleClass('fa-chevron-down fa-chevron-up')
      $(event.currentTarget).find('.fa-lg').toggleClass('fa-chevron-down fa-chevron-up')
    }
  })
})
