$(() => {
  // Add pagination to news archive
  if (window.location.href.indexOf('news') !== -1) {
    const allNews = $('#news-pagination').find('.page')

    const breakPoints = allNews.filter((i, e) => {
      return i % 3 === 0
    })

    breakPoints.each((i, e) => {
      const whichPage = i + 1
      e.dataset.page = whichPage

      if (allNews[allNews.index(e) + 1] !== undefined) {
        allNews[allNews.index(e) + 1].dataset.page = whichPage
      }

      if (allNews[allNews.index(e) + 2] !== undefined) {
        allNews[allNews.index(e) + 2].dataset.page = whichPage
      }

      // if (i === 0) {
      //   $('#pagination-nav').append('<li data-target="previous"><</li>')
      // }

      $('#pagination-nav').append('<li class="news-pager" data-target="' + whichPage + '">' + whichPage + '</li>')

      // if (i === breakPoints.length - 1) {
      //   $('#pagination-nav').append('<li data-target="next">></li>')
      // }
    })

    $('[data-page="1"]').each((i, e) => {
      $(e).addClass('page-active')
    })
    $('[data-target="1"]').addClass('active')

    $('#pagination-nav').on('click', '[data-target]', function (event) {
      const $container = $(event.target).closest('#news-pagination')
      $('html,body').animate({
        scrollTop: $container.offset().top - 140
      }, 500)

      const targetPage = event.target.dataset.target
      $('.news-pager').removeClass('active')
      $(event.target).addClass('active')

      $('.page-active').removeClass('page-active')
      $(`[data-page="${targetPage}"]`).addClass('page-active')
    })
  }
})
