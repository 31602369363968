$(window).on('load resize scroll', function () {
  const marginToAdjust = $('.container').css('margin-left')

  $('.extra-blue').each((i, e) => {
    $(e).css('width', marginToAdjust).css('left', `-${marginToAdjust}`)
  })

  $('.extra-border').each((i, e) => {
    $(e).css('width', `calc(100% + ${marginToAdjust})`)
  })

  $('.break-container-image').css('margin-left', `-${marginToAdjust}`)
})

$(() => {
  if ($('.blue-overlay').length !== 0) {
    $(window).on('load resize', function () {
      const bluePositionTop = $('.blue-overlay').offset().top
      const carouselPositionTop = $('[id*="timeLineCarousel"]').offset().top
      let overlayPosition

      if ($(window).innerWidth() >= 768) {
        overlayPosition = (carouselPositionTop - bluePositionTop * 1.01) * 2
      } else {
        overlayPosition = (carouselPositionTop - bluePositionTop * 1.01) * 1.5
      }

      if (overlayPosition >= 100) {
        $('.blue-overlay').css({
          top: overlayPosition + 'px'
        })
      }
    })
  }
})
