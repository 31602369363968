'use strict'

// const url = 'http://bric.test/wp-json/wp/v2/'
const url = '/wp-json/wp/v2/'
const options = '?_embed&per_page=100'

const wpTenants = $.ajax({
  url: url + 'tenants' + options,
  method: 'GET'
})

module.exports = {
  wpTenants
}
