'use strict'

const api = require('./api.js')
const ui = require('./ui.js')
const click = 'click tap'

const getTenants = () => {
  api.wpTenants.then(ui.populateTenantsArray).then(ui.populateTenantsDom).catch((res) => {})
}

const toggleData = (target) => {
  $('[data-show]').each((i, e) => {
    if ($(e).hasClass('hide') !== true) {
      $(e).addClass('hide')
    }
  })

  $(`[data-show="${target}"]`).removeClass('hide')
}

const resetClick = function(event) {
  toggleData('tenants')
  ui.populateTenantsDom(ui.tenants)
}

const singleTenant = (event) => {
  const thisTenant = event.target

  toggleData('single-tenant')
  ui.domSingleTenant(thisTenant)
}

const selectFloor = (event) => {
  const thisFloor = event.target.dataset.floor
  $('.floor-indicator.active').removeClass('active')
  $('g.active').each((i, e) => {
    $(e).removeClass('active')
  })
  $('#entrances polygon').each((i, e) => {
    $(e).css('visibility', 'hidden')
  })
  $('.active-floor').removeClass('active-floor')
  $(`#floor${thisFloor}`).addClass('active-floor')
  $(event.target).addClass('active')
  toggleData('tenants')
}

const zoomHandler = (event) => {
  let svg = d3.select('#map-svg'),
    width = + svg.attr('width'),
    height = + svg.attr('height')
  const g = d3.select('#wrapper-class')

  const zoom_handler = d3.zoom().on('zoom', zoom_actions).scaleExtent([1, 2])

  // specify what to do when zoom event listener is triggered
  function zoom_actions() {
    g.attr('transform', d3.event.transform)
  }

  // add zoom behaviour to the svg element same as svg.call(zoom_handler);
  zoom_handler(svg)
}

const dynamicFilter = (event) => {
  $('#tenant-search').on('keyup touchend', (event) => {
    // Declare variables
    let a,
      i
    const input = document.getElementById('tenant-search')
    const filter = input.value.toUpperCase()
    const ul = document.getElementById('tenants-list')
    const li = ul.getElementsByTagName('li')

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      a = li[i]
      if (a.innerHTML.toUpperCase().indexOf(filter) > -1 && $('#tenant-search').val().length >= 2) {
        li[i].style.display = ''
      } else {
        li[i].style.display = 'none'
      }
    }
    if ($(event.target).val() !== '') {
      $('#tenants-list').addClass('active')
    } else {
      $('#tenants-list').removeClass('active')
      $('g.active').each((i, e) => {
        $(e).removeClass('active')
      })
      toggleData('tenants')
    }
  })
}

const highlightTenants = (event) => {
  const suite = event.target.dataset.suite
  const building = event.target.dataset.building
  const floor = event.target.dataset.floor
  const entrance = event.target.dataset.entrance.toLowerCase()

  $('.active-floor').removeClass('active-floor')
  $(`#floor${floor}`).addClass('active-floor')
  $('#entrances polygon').each((i, e) => {
    $(e).css('visibility', 'hidden')
  })
  $(`#${entrance}`).css('visibility', 'visible')
  $('g.active').each((i, e) => {
    $(e).removeClass('active')
  })
  $(`[data-name*="${building}_${suite}"]`).each((i, e) => {
    $(e).addClass('active')
  })
}

const hideTenants = (event) => {
  $('#tenants-list').removeClass('active')
}

const switchActiveTenant = (event) => {
  const tenant = $(event.target).parent()
  const building = tenant[0].id.split('_')[1]
  const suite = tenant[0].id.split('_')[2]

  $(`.tenant[data-suite="${suite}"][data-building="${building}"]`).click()
  $('g.active').each((i, e) => {
    $(e).removeClass('active')
  })
  $(`[data-name*="${building}_${suite}"]`).each((i, e) => {
    $(e).addClass('active')
  })
}

const closeTenantBox = (event) => {
  toggleData('tenants')
  resetClick()
  hideTenants()
  $('g.active').each((i, e) => {
    $(e).removeClass('active')
  })
}

const toggleAmenities = (event) => {
  if ($(event.target.checked)[0]) {
    const thisAmen = event.target.getAttribute('data-toggle')
    $(`#${thisAmen}`).css('display', 'block')
  } else {
    const thisAmen = event.target.getAttribute('data-toggle')
    $(`#${thisAmen}`).css('display', 'none')
  }
}

const addHandlers = () => {
  $('.reset-tenants').on(click, resetClick)
  $('.interactive-tenants').on(click, '[data-target]', singleTenant)
  $('.floor-indicator').on(click, selectFloor)
  $('.interactive-tenants').on(click, '.tenant', highlightTenants)
  $('.interactive-tenants').on(click, '.tenant', hideTenants)
  $('[class*="cls-"]').on(click, switchActiveTenant)
  $(':checkbox').change(toggleAmenities)
  $('.interactive-tenants').on(click, '.close-tenant', closeTenantBox)
}

$(() => {
  $('#floor1').addClass('active-floor')
  toggleData('tenants')
  getTenants()
  addHandlers()
  dynamicFilter()
  if ($('.interactive-map').length !== 0) {
      zoomHandler()
  }

  $(document).on('click', () => {
    if ($('g.active').length !== 0) {
      $('path.cls-24').css('opacity', 0)
    } else {
      $('path.cls-24').css('opacity', 1)
      // Remove entrances
      $('#entrances polygon').each((i, e) => {
        $(e).css('visibility', 'hidden')
      })
    }
  })
})

module.exports = {
  getTenants,
  toggleData,
  resetClick,
  singleTenant,
  selectFloor,
  addHandlers
}
