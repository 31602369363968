'use strict'

const api = require('./api.js')
const events = require('./events.js')

const tenants = []

const populateTenantsArray = (res) => {
  res.forEach((e, i) => {
    tenants.push(e.acf)
  })

  return tenants
}

const populateTenantsDom = (array) => {
  $('.tenants').empty()
  array.forEach((e) => {
    $('.tenants').append(`
      <li class="tenant" data-target="${e.name.replace(' ', '-').toLowerCase()}" data-building="${e.building}" data-name="${e.name}" data-floor="${e.floor}" data-suite="${e.suite_num}"
      data-entrance="${e.entrance}">${e.name}

      </li>
      `)
  })

}

const domSingleTenant = (tenant) => {
  const thisTenant = tenant.dataset.target

  const filteredTenant = tenants.filter((e) => {
    return e.name.replace(' ', '-').toLowerCase() === thisTenant
  })

  $('.single-tenant-building').empty().html(`Building ${filteredTenant[0].building}`)
  $('.single-tenant-floor').empty().html(`Floor ${filteredTenant[0].floor}`)
  $('.single-tenant-suite').empty().html(`Suite ${filteredTenant[0].suite_num} `)
  $('.single-tenant-name').empty().html(`${filteredTenant[0].name}`)
  $('.single-tenant-phone').empty().html(`${filteredTenant[0].phone_number}`)
  $('.single-tenant-website').empty().append(`
    <a href="//${filteredTenant[0].website}"
    target="_blank">${filteredTenant[0].website}
    </a>
    `)
  $('.floor-indicator.active').removeClass('active')
  $(`[data-floor="${filteredTenant[0].floor}"]`).addClass('active')
}

module.exports = {
  populateTenantsArray,
  populateTenantsDom,
  domSingleTenant,
  tenants
}
