$(() => {
  $('.navigator').click(function () {
    const target = $('.amenities-nav.active');

    var sibbling;
    if ($(this).hasClass('next-arrow')) {
      sibbling = target.next();
    } else {
      sibbling = target.prev();
    }

    if (sibbling.hasClass('amenities-nav')) {
      sibbling.tab('show');
    }
  });
})
