$(() => {
  if ($('.counter').lenght !== 0) {
    const updateCounter = () => {
      const totalVacancy = $('.vacancy').length
      const currentVacancy = $('.vacancy.active').data('vacancy')

      $('.current').html(currentVacancy)
      $('.counter-total').html(totalVacancy)
    }

    const nextVacant = (event) => {
      const totalVacancy = $('.vacancy').length
      const nextVacancy = ($('.vacancy.active').data('vacancy') + 1)

      $('.vacancy.active').removeClass('active')

      if (nextVacancy > totalVacancy) {
        $(`[data-vacancy="1"]`).addClass('active')
      } else {
        $(`[data-vacancy="${nextVacancy}"]`).addClass('active')
      }
    }

    const previousVacant = (event) => {
      const totalVacancy = $('.vacancy').length
      const previousVacancy = ($('.vacancy.active').data('vacancy') - 1)

      $('.vacancy.active').removeClass('active')

      if (previousVacancy <= 0) {
        $(`[data-vacancy="${totalVacancy}"]`).addClass('active')
      } else {
        $(`[data-vacancy="${previousVacancy}"]`).addClass('active')
      }
    }

    $('.counter-trigger').on('click tap', () => {
      updateCounter()
    })

    $('.counter-plus').on('click tap', () => {
      nextVacant()
      updateCounter()
    })

    $('.counter-minus').on('click tap', () => {
      previousVacant()
      updateCounter()
    })


    updateCounter()
  }
})
