$.fn.isInViewport = function () {
  const elementTop = $(this).offset().top + $(window).height()
  const elementBottom = elementTop + $(this).outerHeight(true)

  const viewportTop = $(window).scrollTop()
  const viewportBottom = viewportTop + $(window).height()

  // return elementBottom > viewportTop && elementTop < viewportBottom
  return $(this).offset().top <= ($(window).scrollTop() + $(window).height())
}

$(window).on('scroll resize', function () {
  if ($('.section-label').length !== 0) {
    $('.section-label').each(function (i, e) {
      if ($(this).isInViewport()) {
        $(this).find('div').addClass('grow')
      }
    })
  }

  if ($(window).scrollTop() > 35) {
    $('.bric-nav').addClass('scrolled')
  } else {
    $('.bric-nav').removeClass('scrolled')
  }

  $('.svg-line').each(function (i, e) {
    if ($(this).isInViewport()) {
      $(this).addClass('draw-itself')
    } else {
      $(this).removeClass('draw-itself')
    }
  })

  if ($('.green-overlay').index() !== -1) {
    if ($('.green-overlay').isInViewport()) {
      $('.green-overlay').addClass('active')
      $('.prop-info-copy').addClass('active')
    } else {
      $('.green-overlay').removeClass('active')
      $('.prop-info-copy').removeClass('active')
    }
  }

  if ($('.amen-navigation').index() !== -1) {
    if ($('.amen-navigation').isInViewport()) {
      $('.amen-navigation').addClass('active')
    } else {
      $('.amen-navigation').removeClass('active')
    }
  }
})


$(window).on('resize scroll', () => {
  if ($(window).scrollTop() >= 35) {
    $('.bric-nav').addClass('locked')
    $('.nav-toggler').addClass('locked')
  } else {
    $('.bric-nav').removeClass('locked')
    $('.nav-toggler').removeClass('locked')
  }

})

$(() => {
  $('.nav-toggler').on('click', function () {
    $('.nav-toggler').toggleClass('open')
    $('.bric-nav').toggleClass('active')
  })

  $('.close-nav').on('click', () => {
    if ($('.nav-toggler').hasClass('open')) {
      $('.nav-toggler').removeClass('open')
    }
    if ($('.bric-nav').hasClass('active')) {
      $('.bric-nav').removeClass('active')
    }
  })
})
