$(() => {
  new WOW().init()

  // make accordion lock opened card on top of the screen

  $('.collapse').on('shown.bs.collapse', (e) => {
    let $card
    if ($('.accord-container').length > 0) {
      $card = $(e.target).closest('.accord-container')
    } else if ($('.accordion-toggler').length > 0) {
      // $card = $(e.target).closest('.accordion-toggler')
    }

    $('html,body').animate({
      scrollTop: $card.offset().top
    }, 500)
  })

  // Animate hero svg's
  $('.anim-on-load').addClass('draw-itself')
})
