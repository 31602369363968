$(() => {
  if (window.location.href.indexOf('#') !== -1) {
    const splitHash = window.location.href.split('/#')
    const whereTo = splitHash[splitHash.length - 1]

    $('html, body').animate({
      scrollTop: $(`#${whereTo}`).offset().top - 125
    }, 1000)
  }

  // Select all links with hashes
  $('a[href*="#"]')
  // Remove links that don't actually link to anything
    .not('[href="#"]').not('[href="#0"]').not('[data-toggle]').click(function (event) {
    // On-page links
    // Figure out element to scroll to
      let target = $(this.hash)
      console.log('target is: ', target)
      target = target.length
        ? target
        : $('[name=' + this.hash.slice(1) + ']')

      // Does a scroll target exist?
      if (target.length) {
      // Only prevent default if animation is actually gonna happen
        event.preventDefault()
        $('html, body').animate({
          scrollTop: target.offset().top - 125
        }, 1000)

        return false
      }
    })
})
